import { Component, OnInit, Input, NgModule } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ChatService } from '../chat.service';
import { SwirlService } from '../swirl.service';

@Component({
  selector: 'app-chat-box',
  templateUrl: './chat-box.component.html',
  styleUrls: ['./chat-box.component.css']
})

export class ChatBoxComponent implements OnInit {
  @Input() isConversation: boolean = false;
  searchForm: FormGroup;
  searchButtonLabel: string = 'SEND';
  searchBarPlaceholder: string = 'Ask me anything, I\'m here to help...';

  messages: any[] = [];
  chatId: number | any;
  isLoading: boolean;

  constructor(
    private fb: FormBuilder,
    private chatService: ChatService,
    private swirlService: SwirlService
  ) {
    this.searchForm = this.fb.group({
      query: ['']
    });
    this.isLoading = false;
  }

  get isSearchButtonDisabled(): boolean {
    return this.searchForm.get('query')?.value?.trim() === '';
  }

  ngOnInit() {
    this.chatService.isConversationStarted$.subscribe(isStarted => {
      if (isStarted && this.chatId) {
        this.fetchMessages(this.chatId);
      }
    });

    const currentChatId = this.chatService.getChatId();
    if (currentChatId) {
      this.fetchMessages(currentChatId);
    }
  }

  fetchMessages(chatId: number) {
    this.swirlService.getMessages(chatId).subscribe(
      messages => {
        this.chatService.setChatId(chatId);
        this.chatService.setMessages(chatId, messages)
        this.messages = this.chatService.getMessages(chatId);
      },
      error => {
        console.error('Error fetching messages:', error);
      }
    );
  }

  onSubmit() {
    if (this.searchForm.valid) {
      const query = this.searchForm.get('query')?.value;
  
      // Add user's message to the messages array immediately
      const userMessage = { role: 'user', content: query };
      
      // Check if chatId is available
      let currentChatId = this.chatService.getChatId() || 0;
      if (!currentChatId) {
        // Initialize new chat session
        this.swirlService.createChat().subscribe(
          response => {
            this.chatId = response.id || 0
            this.chatService.setChatId(this.chatId);
            this.chatService.changeStatus(true);
            // Add user message to the new chat session
            this.chatService.addMessage(this.chatId, userMessage);
            this.isLoading = true;
            // Proceed with sending the message
            this.sendMessage(query, this.chatId);
          },
          error => {
            console.error('Error initializing chat session:', error);
          }
        );
      } else {
        this.chatId = currentChatId
        // Add user message to the existing chat session
        this.chatService.addMessage(this.chatId, userMessage);
        this.chatService.changeStatus(true);
        // Proceed with sending the message
        this.isLoading = true;
        this.sendMessage(query, this.chatId);
      }
      this.searchForm.reset();
    }
  }
  
  sendMessage(query: string, chatId: number) {
    this.swirlService.sendMessage(query, chatId).subscribe(
      response => {
        console.log('Message sent successfully:', response);
        this.chatService.addMessage(chatId, { role: 'assistant', time: response.time, content: response.content, additional_content: response.additional_content });
        // this.messages = this.chatService.getMessages(chatId);
        this.fetchMessages(chatId)
        this.isLoading = false;
      },
      error => {
        console.error('Error sending message:', error);
        this.isLoading = false;
      }
    );
  }
}
