// chat.component.ts
import { Component, ElementRef, AfterViewChecked, ViewChild, OnInit, Input, OnChanges, SimpleChanges, HostListener } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ChatService } from '../chat.service';
import { SwirlService } from '../swirl.service'; // Import SwirlService

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css']
})
export class ChatComponent implements OnInit, OnChanges, AfterViewChecked {
  @ViewChild('chatContainer') private chatContainer!: ElementRef;
  @Input() messages: any[] = [];
  parsedMessages: any[] = [];
  isLoading: boolean = false; // Loading indicator

  constructor(
    private sanitizer: DomSanitizer,
    private chatService: ChatService,
    private swirlService: SwirlService // Inject SwirlService
  ) { }

  ngOnInit(): void {
    this.parseMessages();
  }

  ngAfterViewChecked() {
    this.scrollToBottom();
  }

  private scrollToBottom(): void {
    try {
      this.chatContainer.nativeElement.scrollTop = this.chatContainer.nativeElement.scrollHeight;
    } catch(err) {
      console.error('Scroll to bottom error:', err);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.messages) {
      this.parseMessages();
    }
  }

  parseMessages(): void {
    if (this.messages) {
      this.parsedMessages = this.messages.map(message => {
        return {
          ...message,
          content: this.sanitizer.bypassSecurityTrustHtml(this.formatContent(message.content)) as SafeHtml
        };
      });
      console.log('Parsed Messages:', this.parsedMessages);
    }
  }

  formatContent(content: string): string {
    return content
      .replace(/>\n+/g, '>')   // Remove newlines right after opening tags (like <p>, <div>, etc.)
      .replace(/\n/g, '<br>')  // Replace remaining newlines with <br>
      .replace(/^(<br\s*\/?>)+/, '');
  }

  // HostListener to capture all click events within the component
  @HostListener('click', ['$event'])
  handleLinkClick(event: Event) {
    const target = event.target as HTMLElement;
    if (target && target.classList.contains('question-link') && !this.isLoading) {
      event.preventDefault(); // Prevent default link behavior

      const question = target.textContent?.trim();
      if (question) {
        this.sendFollowUpQuestion(question);
      }
    }
  }

  // Method to send the follow-up question using SwirlService and ChatService
  sendFollowUpQuestion(question: string) {
    const chatId = this.chatService.getChatId();
    if (chatId === undefined) {
      console.error('No active chat session found.');
      return;
    }

    // Create the user message object
    const userMessage = { role: 'user', content: question };

    // Add the user's follow-up question to ChatService
    this.chatService.addMessage(chatId, userMessage);

    // Set loading to true
    this.isLoading = true;

    // Send the follow-up question using SwirlService
    this.swirlService.sendMessage(question, chatId).subscribe(
      response => {
        // Create the assistant's response message object
        const assistantMessage = {
          role: 'assistant',
          time: response.time,
          content: response.content,
          additional_content: response.additional_content
        };

        // Add the assistant's response to ChatService
        this.chatService.addMessage(chatId, assistantMessage);

        // Reset loading
        this.isLoading = false;
      },
      error => {
        console.error('Error sending follow-up question:', error);
        // Optionally, add an error message to the chat
        const errorMessage = {
          role: 'system',
          content: 'Sorry, there was an error processing your request. Please try again later.'
        };
        this.chatService.addMessage(chatId, errorMessage);

        // Reset loading
        this.isLoading = false;
      }
    );
  }
}
