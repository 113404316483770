<div class="row" style="display: flex; justify-content: space-between;">
    <ng-template [ngIf]="result_url" [ngIfElse]="no_url">
        <a [href]="result_url" target="_blank" class="title col content" style="flex-grow: 1;"[innerHTML]="result_string"></a>
    </ng-template>
    <ng-template #no_url class="col content" style="flex-grow: 1;">
        <span class="title" [innerHTML]="result_title"></span>
    </ng-template>
    <ng-template [ngIf]="date">
        <span class="date col-auto content">Publication Date: {{date}}</span>
    </ng-template>
    <!-- <ng-template>
        <span class="title" [innerHTML]="result_num"></span>
    </ng-template> -->
</div>
