<div *ngIf="isShowing">
  <app-summary></app-summary>
</div>
<div *ngIf="!isLoading">
  <div class="row my-2" style="display: flex;">
    <app-info class="from-right" style="padding-left: 1.5rem"></app-info>
    <app-actions class="col"></app-actions>
    <app-pagination class="from-right" style="flex: 0.5; margin-right: 15px;"></app-pagination>
  </div>
  <div class="row my-2" style="display: flex; justify-content: space-between;">
    <app-sort-search style="padding-left: .5rem" ></app-sort-search>
    <div *ngIf="isShowing" class="select-items-container" style="margin-right: 110px; margin-top: 25px;">
      <mat-slide-toggle [(ngModel)]="checkedSelectItems" (click)="toggleChange()">
        <span class="authenticator-label">
          {{ selectItemsLabel }}
        </span>
      </mat-slide-toggle>
    </div>
  </div>
  <div *ngIf="isShowing && checkedSelectItems" style="margin-bottom: 25px;">
    <button class="unselect-button"mat-flat-button (click)="unselectAll()">Unselect All </button>
  </div>
  <div *ngIf="!(result.docs && result.docs.length) && isShowing" style="width: 100%; text-align: center;">
    <span style="font-size: 2rem;">
      {{ isDateFilter ? "No results with dates available" :  "No results available" }}
    </span>
  </div>
  <div *ngIf="false"  class="list-group results">
    <button type="button" *ngFor="let doc of result.docs"
            class="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
      <p *ngIf="highlights[doc['id']][resultMapping.text][0];else other_content" class="preview">
        <strong>{{doc[resultMapping.title]}}</strong>
        - <span [innerHTML]="highlights[doc['id']][resultMapping.text][0]"></span>
      </p>
      <ng-template #other_content>
        <p class="preview">
          <strong>{{doc[resultMapping.title]}}</strong><span *ngIf="doc[resultMapping.teaser]"> - {{doc[resultMapping.teaser][0]}}</span>
        </p>
      </ng-template>
    </button>
  </div>
  <div *ngIf="false" class="list-group results">
    <div class="sg-row sg-header">
      <div class="sg-cell" [style.flex-grow]="column.width" [style.text_align]="column.text_align" *ngFor="let column of columnMapping">
        {{column.label}}
      </div>
    </div>
    <div class="sg-row" *ngFor="let doc of result.docs">
      <div class="sg-cell" [style.flex-grow]="column.width" [style.text_align]="column.text_align" *ngFor="let column of columnMapping">
        {{doc[column.field]}}
      </div>
    </div>
  </div>
  <div *ngIf="resultType === 'table' || (resultType === 'hybrid' && !isCardView)" class="table-responsive">
    <table class="table">
      <thead>
      <tr>
        <th scope="col" [style.text_align]="column.text_align" *ngFor="let column of columnMapping">
          {{column.label}}
        </th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let doc of result.docs">
        <td [style.text_align]="column.text_align" *ngFor="let column of columnMapping">          
          <ng-container #title *ngIf="column.field === 'title'">
            <ng-container #linkedTitle *ngIf="!(doc['url'] === '')">
              <a [href]="doc['url']" [innerHTML]="doc[column.field]"></a>
            </ng-container>    
          </ng-container>
          <ng-container *ngIf="column.field === 'body'">
            <span class="bodyText" [innerHTML]="doc[column.field]"></span>
          </ng-container>
          <ng-container #normalText *ngIf="(!(column.field === 'title') || doc['url'] === '') && !(column.field === 'body')">
            <span [innerHTML]="doc[column.field]"></span>
          </ng-container>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <ul *ngIf="(resultType === 'hybrid' && isCardView) || (resultType !== 'hybrid' && resultLayout) && (!resultType || resultType==='card')" class="list-group results">
    <li class="list-group-item result" *ngFor="let doc of result.docs; let i = index;">
      <div class="d-flex w-100 justify-content-between">
        <app-result-card class="flex-fill" [doc]="doc" [highlight]="highlights[doc.id]" [layout]="resultLayout" [config]="config"></app-result-card>
      </div>
    </li>
  </ul>
  <div class="row my-2">
    <app-actions class="col"></app-actions>
    <app-pagination class="col from-right" [style.flex-grow]="0"></app-pagination>
  </div>
</div>
