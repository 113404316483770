import {Component, Input, OnInit, OnDestroy} from '@angular/core';
import {ConfigResponse, FieldView, ResultCard} from "../shared/config-response";
import { CartItem, ShoppingCart } from '../shared/shopping-cart';
import { ShoppingCartService } from '../shopping-cart.service';
import {SearchService} from '../search.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-result-card',
  templateUrl: './result-card.component.html',
  styleUrls: ['./result-card.component.css']
})
export class ResultCardComponent implements OnInit, OnDestroy {

  @Input() doc: any;
  @Input() highlight: any;
  @Input() layout: ResultCard = new ResultCard();
  @Input() config: ConfigResponse = new ConfigResponse();

  toggleText: string;
  showDetails: boolean;
  cartText: string;
  cartImage?: string;
  inCart: boolean;
  isPayload: boolean;
  isChecked: boolean;
  isCheckboxRendered: boolean;

  isSelectedItemsSubsription: Subscription | undefined;
  isCleanAllToggleSubscription: Subscription | undefined;


  minimumSwirlScore: number;

  constructor(public shoppingCartService: ShoppingCartService, private searchService: SearchService) {
    this.toggleText = 'Show details...'
    this.showDetails = false;
    this.cartText = '';
    this.inCart = false;
    this.isPayload = false;
    this.isChecked = false;
    this.isCheckboxRendered = true;
    this.minimumSwirlScore = 100;
  }

  ngOnInit(): void {
    if(this.config.minimumSwirlScore)
      this.minimumSwirlScore = this.config.minimumSwirlScore
    this.isSelectedItemsSubsription = this.searchService.isSelectedItems.subscribe((isSelected) => {
      this.isCheckboxRendered = isSelected
      if(!isSelected)
        this.searchService.clearSelectedItems()
      else {
        this.isChecked = false
        if(this.searchService.cleanAllToggle.value){
          this.isChecked = this.searchService.isItemSelected(this.doc['swirl_id'])
        }else{
          if(this.isCheckboxRendered)
            this.isChecked = this.searchService.addToSelectedItems(this.doc['swirl_id'], false, this.doc['swirl_score'])
        }
      }
    })
    this.isCleanAllToggleSubscription = this.searchService.isCleanAllToggle.subscribe((isCleanAllToggle) => {
      if(isCleanAllToggle)
        this.isChecked = this.searchService.isItemSelected(this.doc['swirl_id'])
    })
    this.inCart = false;
    this.isPayload = Object.keys(this.doc['payload']).length > 0
    this.cartText = this.config.cart.addText;
    this.cartImage = this.config.cart.addImage;
    this.shoppingCartService.currentShoppingCart.subscribe(cart => {
      if(this.inCart && !cart.items.has(this.doc['id'])) {
        this.inCart = false;
        this.cartText = this.config.cart.addText;
        this.cartImage = this.config.cart.addImage;
      } else if(!this.inCart && cart.items.has(this.doc['id'])) {
        this.inCart = true;
        this.cartText = this.config.cart.removeText;
        this.cartImage = this.config.cart.removeImage;
      }
    });
  }

  toggleDetails(): void {
    if(this.showDetails) {
      this.showDetails = false;
      this.toggleText = 'Show details...';
    } else {
      this.showDetails = true;
      this.toggleText = 'Hide details...';
    }
  }

  toggleCart(): void {
    if(this.inCart) {
      this.shoppingCartService.remove(this.doc['id']);
    } else {
      const cartItem = new CartItem();
      cartItem.id = this.doc['id'];
      this.shoppingCartService.add(cartItem);
    }
  }

  check() {
    this.isChecked 
      ? this.searchService.addToSelectedItems(this.doc['swirl_id'], true, 0) 
      : this.searchService.removeFromSelectedItems(this.doc['swirl_id'])
  }

  ngOnDestroy() {
    if(this.isSelectedItemsSubsription)
      this.isSelectedItemsSubsription.unsubscribe()
  }
}
